import request from '@/api/request.js'

// 获取物流记录接口
export function getDeliveryList (data) {
  return request({
    url: '/api/delivery/list',
    method: 'POST',
    data
  })
}

// 订单商品物流详情
export function getOrderGoods (data) {
  return request({
    url: '/api/orderGoods/list',
    method: 'POST',
    data
  })
}

// 单件商品物流发货
export function sendDelivery (data) {
  return request({
    url: '/api/order/sendDelivery',
    method: 'POST',
    data
  })
}

/**
 * 物流详情
 */
export function getDelivery(data) {
  return request({
    url: '/api/deliveryLogistics/detail',
    method: 'POST',
    data
  })
}

/**
 * 删除物流
 */
export function deleteDelivery(data) {
  return request({
    url: '/api/delivery/delete',
    method: 'POST',
    data
  })
}

// 发货单
export function getDeliveryPage (data) {
  return request({
    url: '/api/dispatchOrder/page',
    method: 'POST',
    data
  })
}
// 发货单编辑
export function editDeliveryPage (data) {
  return request({
    url: '/api/dispatchOrder/edit',
    method: 'POST',
    data
  })
}
// 批量删除
export function pibatchInvalidate (data) {
  return request({
    url: '/api/dispatchOrder/batchInvalidate',
    method: 'POST',
    data
  })
}
