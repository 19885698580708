<template>
  <div class="delivery">
    
    <el-dialog :title="title" :visible.sync="deliveryVisible" :before-close="handleClose" width="800px">
      <div v-loading="loading"></div>
      <el-card v-if="!loading">
        <div slot="header" class="clearfix">
          <span style="font-size:12px;color:red;">最后更新时间：{{lastUpdate}} ，物流持续时间：{{takeTime}}</span>
          <span style="font-size:12px;color:black;float:right;"><a :href="'https://www.baidu.com/s?wd='+expressId" target="_blank">查看百度物流轨迹</a></span>
        </div>        
        <div>{{message}}</div>
        <div class="block" v-if="paths.length > 0">
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(path, index) in paths"
            :key="index"
            :color="path.color"
            size="normal"
            :timestamp="path.time">
            {{path.status}}
          </el-timeline-item>
        </el-timeline>
      </div>
      </el-card>
    </el-dialog>
     
  </div>
</template>

<script>
import { getDelivery } from '@/api/delivery'
export default {
  name: 'orderDelivery',
  data () {
    return {
      reverse: false,
      deliveryVisible: false,
      loading: false,
      title: "查看物流轨迹",
      paths: [],
      message: "",
      lastUpdate: '-',
      takeTime: '-',
      expressId: null,
    }
  },
  created: function () {     
  },  
  methods: {
    init(orderId, expressId) {
      this.deliveryVisible = true
      this.expressId = expressId
      this.title = "物流轨迹：" + expressId
      this.paths = []
      this.message = ""
      const param = {
        "orderId": orderId,
        "expressId": expressId
      }
      this.loading = true
      this.lastUpdate = '-'
      this.takeTime = '-'
      getDelivery(param).then(res => {
        this.loading = false
        if(res.code ===200) {
          this.lastUpdate = res.data.lastUpdate
          this.paths = res.data.paths
          if(this.paths.length > 0) {
            this.paths[0].color = "#0bbd87"
            this.takeTime = res.data.takeTime
          } else {
            this.message = '暂无轨迹'
          }
        } else {
          this.message = res.message
        }
      }).catch(err=>{
        this.loading = false
      })
    },
    handleClose () {
      this.deliveryVisible = false
      this.loading = false
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
