<template>
  <div>
    <el-dialog
      title="填写退款的原因"
      :visible.sync="reasonflag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form :inline="true" label-width="100px">
        <el-form-item label="退款的原因" style="width: 100%">
          <el-input
            type="textarea"
            :rows="4"
            v-model="reason"
            clearable
            placeholder="请输入"
            style="width: 400px"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click.native.prevent="applyCancelOrder"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-dialog
      title="填写物流信息"
      :visible.sync="expressFlag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form :inline="true" label-width="100px">
        <el-form-item label="物流公司" style="width: 100%">
          <el-input
            v-model="expressCo"
            placeholder="请输入快递公司"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="快递单号" style="width: 100%">
          <el-input
            v-model="expressId"
            placeholder="请输入快递单号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <el-button
        type="primary"
        @click.native.prevent="sendDelivery"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-drawer :visible.sync="visible" title="订单详情" size="75%" @closed="onClose">
      <div>
        <div class="contents">
          <div style="padding-top: 0">
            <div class="kuanTT">
              <div class="kuanTitle">订单基础信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column label="订单号" width="300">
                  <template slot-scope="scope">
                    <p>订单编号：{{ scope.row.onlineOrderId }}</p>
                    <p>开团易ID：{{ scope.row.id }}</p>
                  </template>
                </el-table-column>
                <!-- <el-table-column
        prop="onlineOrderId"
        label="订单号"
        width="200"
        >
      </el-table-column> -->
                <el-table-column prop="statusDescription" label="订单状态">
                </el-table-column>
                <el-table-column prop="orderPrice" label="订单金额">
                </el-table-column>
                <el-table-column prop="deliveryPrice" label="运费">
                </el-table-column>
                <el-table-column prop="originOrderPrice" label="改价前订单金额" width="120">
                </el-table-column>
                <el-table-column prop="originDeliveryPrice" label="改价前运费">
                </el-table-column>
                <el-table-column prop="retailerName" label="渠道(团长)">
                </el-table-column>
                <el-table-column prop="supplierName" label="供应商">
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT">
              <div class="kuanTitle">商品明细：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="goodsName" label="商品名称">
                </el-table-column>
                <el-table-column prop="specName" label="规格">
                </el-table-column>
                <el-table-column prop="specCode" label="规格编码">
                </el-table-column>
                <el-table-column prop="num" label="数量"> </el-table-column>

                <el-table-column label="匹配商品">
                  <template slot-scope="scope">
                    <p><span>商品：</span>{{ scope.row.spuName }}</p>
                    <p><span>规格：</span>{{ scope.row.skuName }}</p>
                    <p><span>编码：</span>{{ scope.row.skuCode }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="goodsSalePrice" label="供货价">
                </el-table-column>
                <el-table-column
                  prop="originGoodsSalePrice"
                  label="改价前供货价"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="kuanTT">
              <div class="kuanTitle">收货人：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="consigneeName" label="收货人">
                </el-table-column>
                <el-table-column prop="consigneePhone" label="收货电话">
                </el-table-column>
                <el-table-column prop="consigneeProvince" label="省" width="70">
                </el-table-column>
                <el-table-column prop="consigneeCity" label="市" width="70">
                </el-table-column>
                <el-table-column prop="consigneeCounty" label="区" width="70">
                </el-table-column>
                <el-table-column prop="consigneeAddress" label="收货地址">
                </el-table-column>
                <el-table-column width="100" label="操作">
                  <template>
                    <el-button
                      type="text"
                      size="small"
                      @click="modifyConsigneeAddress"
                    >
                      修改收货信息
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT">
              <div class="kuanTitle">支付信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="payStatusDescription" label="支付状态">
                </el-table-column>
                <el-table-column prop="payTypeDescription" label="支付方式">
                </el-table-column>
                <el-table-column prop="payTime" label="支付时间">
                </el-table-column>
                <el-table-column prop="payId" label="支付单号">
                </el-table-column>
                <el-table-column
                  prop="refundStatusDescription"
                  label="退款状态"
                >
                </el-table-column>
                <el-table-column prop="refundPrice" label="退款金额">
                </el-table-column>
                <el-table-column prop="refundTime" label="退款时间">
                </el-table-column>
                <el-table-column  label="退款记录"
                fixed="right"
                width="350"
                >
                    <template slot-scope="scope">
                      <el-button 
                        size="mini"
                        :disabled="scope.row.refundPrice - scope.row.orderPrice >=0"
                        @click="() => showOfflineRefundFlag = true"
                      >
                        标记已线下退款
                      </el-button>

                      <el-button 
                        v-if="role == 'supplier'"
                        size="mini"
                        type="success"
                        :disabled="scope.row.refundPrice - scope.row.orderPrice >=0"
                        @click="() => $refs.orderRefundDialog.onOpen(scope.row)"
                      >
                        操作退款
                      </el-button>

                      <el-button 
                      type="text"
                        :disabled="!scope.row.refundId"
                        @click="showRefundHistory(scope.row)"
                      >
                        查看退款记录
                      </el-button>
                    </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT">
              <div class="kuanTitle">下单人信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="buyerAccount" label="下单人">
                  <template slot-scope="scope" >
                    {{scope.row.buyerAccount || '-'}}
                  </template>
                </el-table-column>
                <el-table-column prop="orderTime" label="下单时间">
                </el-table-column>
                <el-table-column prop="followNo" label="跟团号">
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT">
              <div class="kuanTitle">订单取消信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column
                  prop="cancelStatusDescription"
                  label="取消状态"
                >
                </el-table-column>
                <el-table-column prop="cancelReason" label="取消原因">
                </el-table-column>
                <el-table-column prop="rejectCancelReason" label="拒绝取消原因">
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT" v-if="role == 'supplier'">
              <div class="kuanTitle">收单信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="createTime" label="收单时间">
                </el-table-column>
                <el-table-column prop="fileName" label="收单文件">
                </el-table-column>
                <el-table-column prop="wechatGroupName" label="收单群">
                </el-table-column>
                <el-table-column prop="wechatNickname" label="文件上传用户">
                </el-table-column>
                <!-- <el-table-column
          prop="fileId"
          label="文件编号">
        </el-table-column>
        <el-table-column
          prop="batchNo"
          width="300"
          label="上传批次编号">
        </el-table-column> -->
              </el-table>
            </div>

            <div class="kuanTT" v-if="role == 'retailer'">
              <div class="kuanTitle">交单信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column label="是否推送供应商群">
                  <template slot-scope="scope">{{scope.row.sendSupplierGroup==1 ? '是' : '否'}}</template>
                </el-table-column>
                <el-table-column prop="sendSupplierTime" label="交单供应商">
                </el-table-column>
                <el-table-column prop="sendSupplierGroupAt" label="推送供应商群">
                </el-table-column>
                <el-table-column prop="sendSupplierGroupFilename" label="交单文件名">
                </el-table-column>
                <el-table-column prop="dispatchStatus" label="推送云仓状态">
                  <template slot-scope="scope">
                    <span>{{scope.row.dispatchStatusDescription}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT" v-if="role == 'retailer'">
              <div class="kuanTitle">渠道信息：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="platform" label="渠道平台">
                  <template slot-scope="scope">
                    {{ scope.row.platform ? scope.row.platform : "-" }}
                  </template>
                </el-table-column>
                <el-table-column prop="shopName" label="渠道名称">
                </el-table-column>
                <el-table-column prop="shopId" label="渠道ID">
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT" v-if="role == 'retailer'">
              <div class="kuanTitle">匹配基础商品：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column label="已匹配">
                  <template slot-scope="scope">
                    {{ scope.row.retailerSkuMatched == 1 ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column prop="itemCode" label="匹配规则">
                </el-table-column>
                <el-table-column
                  prop="matchedSupplierOrgName"
                  label="匹配供应商"
                >
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT" v-if="role == 'supplier'">
              <div class="kuanTitle">匹配商品：</div>
              <el-table
                :data="orderDetail"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column label="已匹配">
                  <template slot-scope="scope">
                    {{ scope.row.matchedSku == 1 ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column prop="matchTypeDescription" label="匹配方式">
                </el-table-column>
                <el-table-column prop="itemCode" label="匹配规则">
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT" v-if="isTicketOrder != 1">
              <div class="kuanTitle">商品发货明细：</div>
              <el-table
                :data="orderGoods"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <!-- <el-table-column width="180" prop="id" label="发货流水">
                </el-table-column> -->
                <el-table-column prop="code" label="编号" width="50">
                </el-table-column>
                <el-table-column prop="goodsName" label="商品名称">
                </el-table-column>
                <el-table-column prop="goodsSpec" label="规格">
                </el-table-column>
                <el-table-column prop="goodsCode" label="规格编码">
                </el-table-column>
                <el-table-column width="150" prop="status" label="商品状态">
                  <template slot-scope="scope">
                    <el-tag type="info">{{ goodsStatus[scope.row.status] }}</el-tag>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="packageCode" label="包裹编号">
                </el-table-column> -->
                <el-table-column prop="expressCo" label="发货快递">
                </el-table-column>
                <el-table-column prop="expressId" label="发货单号">
                </el-table-column>
                <el-table-column
                  label="操作"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button
                      @click="showSendDeliveryDialog(scope.row)"
                      type="text"
                      size="small"
                      >发货</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT" v-if="isTicketOrder == 1">
              <div class="kuanTitle">券码明细：</div>
              <el-table
                :data="goodsList"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column width="180" prop="id" label="流水号">
                </el-table-column>
                <el-table-column width="200" prop="expressId" label="券码">
                </el-table-column>
                <el-table-column
                  width="130"
                  prop="status"
                  label="状态"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tag>{{ goodsStatus[scope.row.status] }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="sendTime" label="券码发放时间">
                </el-table-column>
                <el-table-column
                  label="券码是否核销"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.off == 1 ? "是" : "否" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="offTime"
                  label="核销时间"
                  header-align="center"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="操作"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.status == 1"
                      @click="showCancelOrderDialog(scope.row)"
                      type="text"
                      size="small"
                      >申请退款</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="kuanTT">
              <div class="kuanTitle">物流记录：</div>
              <el-table
                :data="deliveries"
                style="width: 100%"
                :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
                border
              >
                <el-table-column prop="expressCo" label="物流公司">
                </el-table-column>
                <el-table-column prop="expressId" width="250" label="物流单号">
                </el-table-column>
                <el-table-column prop="createTime" label="上传时间" width="150">
                </el-table-column>
                <el-table-column prop="returnTime" label="返单时间" width="150">
                </el-table-column>

                <el-table-column
                  label="操作"
                  width="180"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="small"
                      type="text"
                      @click="detailDeliveryFun(scope.row)"
                      >查看轨迹</el-button
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <el-popconfirm
                      title="确定要删除吗？"
                      @confirm="deleteDeliveryFun(scope.row)"
                    >
                      <el-button size="small" slot="reference" type="text"
                        >删除物流</el-button
                      >
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="kuanTT">
            <div class="kuanTitle">操作历史：</div>
            <el-table
              :data="orderJilu"
              style="width: 100%"
              :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
              border
            >
              <el-table-column label="操作动作" width="150">
                <template slot-scope="scope">
                  <el-tag>{{ scope.row.operationDescription }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="remark"
                label="备注"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <div style="white-space: pre-wrap">
                    {{ scope.row.remark }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="操作时间"
                width="150"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="operatorName"
                label="操作人"
                width="170"
                header-align="center"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      title="修改收货人信息"
      :visible.sync="addressFlag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form
        :inline="true"
        ref="addressObj"
        :model="addressObj"
        label-width="100px"
      >
        <el-form-item label="收货人姓名" style="margin-bottom: 10px">
          <el-input
            v-model="addressObj.name"
            clearable
            style="width: 290px"
            placeholder="请输入收货人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" style="margin-bottom: 10px">
          <el-input
            v-model="addressObj.phone"
            maxlength="11"
            clearable
            style="width: 290px"
            placeholder="请输入收货人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人地址">
          <el-input
            v-model="addressObj.address"
            clearable
            style="width: 290px"
            type="textarea"
            :rows="3"
            placeholder="请输入收货人地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click.native.prevent="modifyAddress"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-dialog :title="'订单 ' + orderDetail[0].onlineOrderId + ' 退款记录'" 
      :visible.sync="showRefundDetailFlag"
      :before-close="layerClose"
      width="600px"
    >

    <el-collapse accordion v-model="orderDetail[0].refundId">
        <el-collapse-item :title="'退款单号：' + refundDetail.id" :name="refundDetail.id" v-for="(refundDetail,index) in refundRecord" :key="index">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in refundDetail.histories"
                :key="index"
                :type="activity.primary"
                :size="activity.normal"
                :timestamp="activity.time">
                <span style="font-weight:bold;">{{activity.progress}}</span> {{activity.description}}
              </el-timeline-item>
          </el-timeline>
        </el-collapse-item>
      </el-collapse>

    </el-dialog>

    <el-dialog title="标记订单已线下退款" 
      :visible.sync="showOfflineRefundFlag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form ref="refund" :model="offlineRefundParam"  size="mini" label-width="150px">
        <el-form-item label="线下退款渠道" required>
          <el-radio-group v-model="offlineRefundParam.channel" size="mini">
            <el-radio-button v-for="(item, index) in channelOtions" :key="index" :label="item.value"
              :disabled="item.disabled">{{item.label}}</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="退货数量" required>
            <el-input-number
              size="mini"
              v-model="offlineRefundParam.refundGoodsNum"
              :min="1"
              :max="order.num"
              :controls="true"
              placeholder="退款商品数量"
            >
            </el-input-number>
            <template><span style="margin-left:10px;font-size:12px;">订单数量：{{order.num}}</span></template>
        </el-form-item>

        <el-form-item label="线下退款金额" required style="width:70%">
          <el-input-number v-model="offlineRefundParam.price" placeholder="请输入退款金额" 
          :precision="2" :min="0.01" :step="1" :max="order.orderPrice"
          clearable >
          </el-input-number>
          <template><span style="margin-left:10px;font-size:12px;">订单金额：￥{{order.orderPrice}}</span></template>
        </el-form-item>

        <el-form-item label="是否退货" required style="width:70%">
            <el-radio-group v-model="offlineRefundParam.returnGoods">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="退货快递公司" required style="width:70%" v-show="offlineRefundParam.returnGoods">
          <el-input v-model="offlineRefundParam.refundExpressCo"  placeholder="请输入退货快递公司"
            ></el-input>
        </el-form-item>

        <el-form-item label="退货快递单号" required style="width:70%" v-show="offlineRefundParam.returnGoods">
          <el-input v-model="offlineRefundParam.refundExpressId"  placeholder="请输入退货快递单号"
            ></el-input>
        </el-form-item>

        <el-form-item label="线下退款备注" required style="width:70%">
          <el-input v-model="offlineRefundParam.remark" type="textarea" placeholder="请输入退款原因"
            :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
        </el-form-item>

      </el-form>    
      <div class="dialog-footer" slot="footer">
        <el-button @click="() => showOfflineRefundFlag=false">取消</el-button>
        <el-button type="primary" @click="offlineRefundIt">提交</el-button>
      </div>

    </el-dialog>

    <order-delivery-dialog ref="orderDeliveryDialog"></order-delivery-dialog>

    <order-refund-dialog ref="orderRefundDialog" @close="()=>getOrderDetails(order.id)"></order-refund-dialog>

  </div>
</template>

<script>
import {
  getOrderDetail,
  getOperation,
  applyCancelOrder,
  batchModifyConsignee,
  getRefundHistory,
  offlineRefund
} from "@/api/orders";
import {
  getDeliveryList,
  getOrderGoods,
  sendDelivery,
  deleteDelivery,
} from "@/api/delivery";

import OrderDeliveryDialog from "./delivery";
import OrderRefundDialog from "./refund";

let moment = require("moment");
export default {
  name: "orderDetail",
  components: { OrderDeliveryDialog, OrderRefundDialog },
  data() {
    return {
      id: "",
      visible: false,
      order: {},
      orderDetail: [{}],
      refundRecord: [],
      goodsList: [],
      orderJilu: [],
      deliveries: [],
      role: null,
      isTicketOrder: 0,
      orderGoods: [],
      goodsStatus: [
        "待发货",
        "已发货",
        "申请退款中",
        "已退款退货",
        "未退款退货中",
        "已退款已退货",
        "已退货未退款",
        "已退款无需退货",
      ],
      reasonflag: false,
      reason: "",
      goods: {},
      expressFlag: false,
      goodsRow: {},
      expressCo: "",
      expressId: "",
      addressFlag: false,
      addressObj: {
        orderId: null,
        name: "",
        phone: "",
        address: "",
      },
      showRefundDetailFlag: false,
      refundDetail: {},
      showOfflineRefundFlag: false,
      offlineRefundParam: {
        channel: "微信",
        price: 1,
        remark: undefined,
        refundGoodsNum: 1,
        returnGoods: 1,
        refundExpressCo: '',
        refundExpressId: '',
      },
      channelOtions: [{
        "label": "微信",
        "value": "微信"
      }, {
        "label": "支付宝",
        "value": "支付宝"
      }, {
        "label": "银行卡",
        "value": "银行卡"
      }],
    };
  },
  methods: {   
    onClose() {
      this.$emit("close")
    },
    offlineRefundIt() {
      const id = this.orderDetail[0].id;
      const param = {
        ...this.offlineRefundParam,
        role: this.role,
        id: id,
      }
      offlineRefund(param).then(res => {
        if(res.code === 200) {
          this.$message.success(res.message)
          this.getOrderDetails(id)
          this.getOrderGoods(id)
          this.layerClose()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showRefundHistory(row) {
      console.log("showRefundHistory", row.id)
      const param = {
        orderId: row.id
      }
      const that = this
      getRefundHistory(param).then(res => {
        if(res.code === 200) {
          that.showRefundDetailFlag = true
          that.refundRecord = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    modifyAddress() {
      const that = this;
      const param = {
        query: {
          action: "SELECT",
          orderIds: [that.addressObj.orderId],
        },
        ...that.addressObj,
      };
      batchModifyConsignee(param).then((res) => {
        if (res.code === 200) {
          that.addressFlag = false;
          that.getOrderDetails(that.addressObj.orderId)
        } else {
          that.$message.error(res.message);
        }
      });
    },
    modifyConsigneeAddress() {
      const row = this.orderDetail[0];

      this.addressFlag = true;
      this.addressObj.orderId = row.id;
      this.addressObj.name = row.consigneeName;
      this.addressObj.phone = row.consigneePhone;
      this.addressObj.address = row.consigneeAddress;
    },
    detailDeliveryFun(row) {
      const that = this;
      that.$nextTick(() => {
        that.$refs.orderDeliveryDialog.init(row.orderId, row.expressId);
      });
    },
    deleteDeliveryFun(row) {
      const that = this;
      deleteDelivery(row).then((res) => {
        if (res.code === 200) {
          that.$message.success(res.message);
          that.getDeliveryList(that.id);
          that.getJiluList(that.id);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    refresh(ids) {
      const that = this;
      that.getOrderDetails(ids);
      that.getJiluList(ids);
      that.getDeliveryList(ids);
      that.getOrderGoods(ids);
    },
    init(id, role) {
      const that = this;
      this.id = id;
      this.role = role;
      this.visible = true;
      this.$nextTick(() => {
        that.refresh(that.id);
      });
    },
    showSendDeliveryDialog(row) {
      const that = this;
      that.goodsRow = row;
      this.expressFlag = true;
    },
    showCancelOrderDialog(row) {
      this.goods = row;
      this.reasonflag = true;
    },
    sendDelivery() {
      const that = this;

      const row = that.goodsRow;
      const param = {
        sendType: 1,
        orderGoodsCodes: [row.code],
        id: row.orderId,
        expressCo: that.expressCo,
        expressId: that.expressId,
      };
      sendDelivery(param).then((res) => {
        if (res.code === 200) {
          that.$message.success("操作成功");
          this.expressFlag = false;
          that.refresh(row.orderId);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    layerClose() {
      this.addressFlag = false;
      this.reasonflag = false;
      this.expressFlag = false;
      this.showRefundDetailFlag = false;
      this.showOfflineRefundFlag = false;
      this.tuikuanflag = false;
    },
    applyCancelOrder() {
      const that = this;
      const row = that.goods;
      const param = {
        query: {
          action: "SELECT",
          orderIds: [row.orderId],
        },
        reason: that.reason,
        cancelPartOrder: 1,
        orderGoodsIds: [row.id],
        num: 1,
        returnPackage: "0",
      };
      applyCancelOrder(param).then((res) => {
        if (res.code === 200) {
          that.$message.success("申请成功");
          that.reasonflag = false;
          that.refresh(row.orderId);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    getOrderGoods(id) {
      const that = this;
      const param = {
        id: id,
        role: that.role,
      };
      getOrderGoods(param).then((res) => {
        if (res.code === 200) {
          that.orderGoods = res.data;
        } else {
          that.$message.error(res.message);
        }
      });
    },
    getDeliveryList(id) {
      const that = this;
      const param = {
        id: id,
        role: that.role,
      };
      getDeliveryList(param).then((res) => {
        console.log("getDeliveryList", res);
        if (res.code === 200) {
          that.deliveries = res.data;
        } else {
          that.$message.error(res.message);
        }
      });
    },
    getOrderDetails(ids) {
      var that = this;
      var objs = {};
      objs.id = ids;
      objs.role = this.role;
      getOrderDetail(objs).then((res) => {
        console.log("orderdetail", res);
        if (res.code === 200) {
          that.isTicketOrder = res.data.isTicketOrder;
          that.goodsList = res.data.goodsList;
          that.order = res.data
          that.orderDetail = [that.order];
        } else {
          that.$message.error(res.message);
        }
      });
    },
    getJiluList(ids) {
      var that = this;
      var objs = {};
      objs.id = ids;
      objs.role = this.role;
      getOperation(objs).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.orderJilu = res.data;
        } else {
          that.$message.error(res.message);
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.el-button--primary {
  color: #fff;
  background-color: #07c160;
  border-color: #07c160;
}
.el-form-item__label {
  color: #999;
  font-size: 12px;
}
.formTable {
  width: 70%;
  display: block;
  padding: 50px;
}
.kuanTT {
  clear: both;
  display: block;
  overflow: hidden;
  padding: 5px 0 5px;
}
.kuanTitle {
  display: block;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.contents {
  padding: 0px 20px;
}
.el-table__fixed-right{
    height: 100% !important;
}
</style>