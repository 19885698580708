<template>
  <div>
    <el-dialog
      title="我要退款"
      :visible.sync="tuikuanflag"
      :before-close="layerClose"
      width="600px"
    >
      <div class="tuikuancss">
        <p>退款订单：{{ tuikuanObjs.onlineOrderId }}</p>
        <p>
          退款商品：{{ tuikuanObjs.goodsName }}~{{
            tuikuanObjs.specName ? tuikuanObjs.specName : 0
          }}
        </p>
        <p>订单金额：￥{{ tuikuanObjs.orderPrice }}，商品数量：{{ tuikuanObjs.num }}，供货价： ￥{{
            tuikuanObjs.goodsSalePrice ? tuikuanObjs.goodsSalePrice : "未知"
          }}</p>
        <p>
          退货数量：
          <el-input-number
            v-model="refundNum"
            :min="1"
            :max="tuikuanObjs.num"
            :controls="true"
            @input="handleChangeRefundPrice"
            style="width: 140px"
            placeholder="退款商品数量"
          >
          </el-input-number>
        </p>
        <p>
          退款金额：
          <el-input-number
            v-model="tuikuanPrice"
            maxlength="10"
            :min="0.01"
            :max="tuikuanObjs.orderPrice"
            style="width: 140px"
            placeholder="请输入退款金额"
          ></el-input-number>
        </p>

        <p>是否退货：
            <el-radio-group v-model="tuikuanObjs.returnGoods">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
            </el-radio-group>
        </p>

        <p v-show="tuikuanObjs.returnGoods">退货快递：
          <el-input v-model="tuikuanObjs.refundExpressCo"  placeholder="请输入退货快递公司"
            style="width: 250px"
            ></el-input>
        </p>

        <p v-show="tuikuanObjs.returnGoods">退货单号：        
          <el-input v-model="tuikuanObjs.refundExpressId"  placeholder="请输入退货快递单号"
                      style="width: 250px"
            ></el-input>
        </p>


        <p style="line-height: 50px">
          退款原因：
          <el-input
            type="textarea"
            :row="2"
            v-model="tuikuanRemark"
            clearable
            placeholder="请输入退款原因"
            style="width: 250px"
          ></el-input>
        </p>

        <p style="line-height: 50px">
          操作网银打款进行退款
          <el-tooltip class="item" effect="dark" content="只有线下结算、银行转账和网银打款支付的订单才可以操作网银退款" 
          placement="top-end">
             <i class="el-icon-question"></i>   
          </el-tooltip>
          ：          
          <el-radio-group v-model="refundByBank" :disabled="true">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </p>

        <div style="clear: both; margin-bottom: 20px"></div>

        <div v-show="refundByBank == 1">
          <el-form v-model="remit" label-width="300px;" label-position="right" size="mini">
            <el-form-item label="收款方银行卡" required>
              <el-input
                v-model="remit.accountNo"
                placeholder="请输入银行卡号，例如：6212260200062388891"
                style="width: 60%"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款方开户名" required>
              <el-input
                v-model="remit.accountName"
                placeholder="请输入银行账号名称，例如：张三"
                style="width: 60%"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户银行名称" required>
              <el-select
                v-model="remit.bankName"
                placeholder="请选择开户行，可输入关键词搜索"
                filterable
                clearable
                @change="(val) => (remit.bankCode = val)"
                style="width: 60%"
              >
                <el-option
                  v-for="item in banks"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="银行账户类型" required>
              <el-radio-group v-model="remit.bankCardType" size="mini">
                <el-radio-button
                  v-for="(item, index) in bankAccountTypeOptions"
                  :key="index"
                  :label="item.value"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>

            <el-form-item label="网银打款密码" required>
                <el-input v-model="remit.password" placeholder="请输入网银打款密码" style="width:30%;" show-password></el-input>
                <label style="margin-left:10px;font-size:12px;"><el-button size="mini" type="text" plain @click="() => setRemitPasswordFlag=true">设置网银打款密码</el-button></label>
            </el-form-item>

          </el-form>
        </div>
      </div>
    <div class="dialog-footer" slot="footer" >
          <el-button @click="() => (tuikuanflag = false)">取消</el-button>
          <el-button type="primary" @click="tuikuanFuns" :loading="loading"
            >提交</el-button
          >
        </div>
    </el-dialog>

    <remit-password :visible.sync="setRemitPasswordFlag"></remit-password>
  </div>
</template>
<script>
import { queryBankList } from "@/api/payorder";
import { MessageBox } from "element-ui";
import { batchRefund } from "@/api/orders.js";
import RemitPassword from '../payorder/remitPassword.vue';

export default {
  inheritAttrs: false,
  components: { RemitPassword },
  props: [],
  data() {
    return {
      tuikuanflag: false,
      tuikuanObjs: {
        refundGoodsNum: 1,
        returnGoods: 1,
        refundExpressCo: '',
        refundExpressId: '',
      },
      refundNum: 1,
      tuikuanPrice: 0,
      tuikuanRemark: "",
      remit: {},
      setRemitPasswordFlag: false,
      bankAccountTypeOptions: [
        {
          label: "储蓄卡",
          value: "DEBIT_CARD",
        },
        {
          label: "对公账户",
          value: "ENTERPRISE_ACCOUNT",
        },
        {
          label: "单位结算卡",
          value: "UNIT_SETTLEMENT_CARD",
        },
        {
          label: "存折",
          value: "PASSBOOK",
        },
      ],
      banks: [],
      refundByBank: 0,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getBanks();
  },
  mounted() {
  },
  methods: {
    onOpen(order) {
      this.tuikuanPrice = order.orderPrice || 1;
      this.tuikuanflag = true;
      this.tuikuanObjs = order;
      this.remit = {}
      this.refundByBank = order.payType == 1 || order.payType ==7 || order.payType == 8 ? 1 : 0
    },
    onClose() {},
    layerClose() {
      this.tuikuanflag = false;
    },
    handelConfirm() {},
    tuikuan() {
      var that = this;
      that.tuikuanflag = true;
      that.refundNum = that.tuikuanObjs.num;
    },
    tuikuanFuns() {
      var that = this;
      var objs = {
        id: that.tuikuanObjs.id,
        price: that.tuikuanPrice,
        reason: that.tuikuanRemark,        
        refundByBank: that.refundByBank,
        refundGoodsNum: that.refundNum,
        returnGoods: that.tuikuanObjs.returnGoods,
        refundExpressCo: that.tuikuanObjs.refundExpressCo,
        refundExpressId: that.tuikuanObjs.refundExpressId,
        remit: that.remit,
      };

      MessageBox.alert("请确保核对退款金额无误，如果使用网银退款，退款会在1个工作日内进入收款方银行卡。", "温馨提示", {
        confirmButtonText: "确认退款",
        type: "warning",
      }).then(() => {
        that.loading = true;
        batchRefund(objs)
          .then((res) => {
            that.loading = false;
            if (res.code === 200) {
              that.$message.success(res.message);
              that.tuikuanflag = false;
              that.$emit("close")
            } else {
              that.$message.error(res.message);
            }
          })
          .catch((err) => {
            that.loading = false;
          });
      }).catch(()=>{});
    },
    handleChangeRefundPrice(n) {
      const price = this.tuikuanObjs.goodsSalePrice * n;
      if (n > this.tuikuanObjs.num) return;
      if (price > this.tuikuanObjs.orderPrice) return;

      this.tuikuanPrice = price;
      this.refundNum = n;
    },
    getBanks(name) {
      const param = {
        name: name,
      };
      const that = this;
      queryBankList(param).then((res) => {
        if (res.code === 200) {
          that.banks = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.tuikuancss {
  margin-top: -20px;
  padding-left:10px;
}
.tuikuancss p {
  padding: 10px 0 0 0;
  display: block;
}
.red {
  color: rgb(236, 78, 15);
}
</style>
